<template>
  <b-overlay :show="fetching" rounded="sm">
    <div class="page">
      <div class="page__title">
        {{ getModuleConfig("staff_specific_page.form_title") }}
      </div>
      <div class="page__desc text-center">
        {{ getModuleConfig("staff_specific_page.form_desc") }}
      </div>

      <div class="field mb-2">
        <div class="sales__wrapper">
          <div class="card text-center d-flex flex-column">
            <div v-if="showAvatar">
              <img
                v-if="displayAvatar(staff)"
                :src="displayAvatar(staff)"
                class="card-img-top"
              />
              <img v-else src="./default_staff_img.jpg" class="card-img-top" />
            </div>

            <div class="p-3 flex-fill d-flex flex-column">
              <div class="flex-fill">
                <table
                  v-if="staffFields.length != 0"
                  class="mx-auto my-3 align-text-top"
                >
                  <tr v-for="(field, index) in staffFields" :key="index">
                    <template v-if="field.title">
                      <td class="font-weight-bold text-nowrap align-text-top">
                        {{ field.title }}
                      </td>
                      <td class="text-left align-text-top">
                        {{ displayField(staff, field) }}
                      </td>
                    </template>
                    <template v-else>
                      <td colspan="2" class="align-text-top">
                        {{ displayField(staff, field) }}
                      </td>
                    </template>
                  </tr>
                </table>
                <template v-else>
                  <div>{{ staff.name }}</div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page__button s-space-y-4">
      <MemberButton
        class="s-btn-bg-primary mb-2"
        :disable-button="!selectedStaff.id"
        @custom-click="handleSubmit"
        :button-text="displayButtonText"
        :button-url="
          getModuleConfig('staff_specific_page.bind_button.button_url')
        "
        :type="getModuleConfig('staff_specific_page.bind_button.button_action')"
        :use-callback="true"
      >
      </MemberButton>
      <MemberButton
        :button-style="`s-btn-outline-primary`"
        v-if="getModuleConfig('staff_specific_page.button_text')"
        :button-text="getModuleConfig('staff_specific_page.button_text')"
        :button-url="getModuleConfig('staff_specific_page.button_url')"
        :type="getModuleConfig('staff_specific_page.button_action')"
      >
      </MemberButton>
    </div>
  </b-overlay>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import { mapGetters } from "vuex";
import liff from "@line/liff";
import staffApi from "@/apis/liff/v2/staff";
import mixinStaff from "@/mixins/liff/staff";
import LiffChecker from "@/utils/LiffChecker";

export default {
  mixins: [mixinStaff],
  computed: {
    ...mapGetters("liffGeneral", ["liffId"]),
    ...mapGetters({
      getModule: "liffModule/getModule",
    }),
    branchProvider() {
      return this.getModuleConfig("staff_specific_page.branch_provider");
    },
    showAvatar() {
      return (
        this.getModuleConfig("staff_specific_page.staffs_list.show_avatar") ??
        true
      );
    },
    staffFields() {
      return (
        this.getModuleConfig("staff_specific_page.staffs_list.fields") ?? []
      ).sort((a, b) => a.order - b.order);
    },
    displayPlaceholder() {
      const text = this.getModuleConfig(
        "staff_specific_page.default_select_text"
      );
      return text == "" || text == null || text == "null"
        ? "請選擇區域櫃點"
        : text;
    },
    displayButtonText() {
      const text = this.getModuleConfig(
        "staff_specific_page.bind_button.button_text"
      );
      if (text) {
        return this.templateReplace(text, { staff: this.selectedStaff });
      } else {
        return this.getModuleConfig(
          "staff_specific_page.bind_button.button_default_text"
        );
      }
    },
    showBranchCode() {
      return (
        this.getModuleConfig("staff_specific_page.show_branch_code") ?? true
      );
    },
    staffId() {
      return this.$route.params.staffId;
    },
  },
  components: {
    MemberButton,
  },
  data() {
    return {
      fetching: true,
      selectedStaff: {
        id: null,
      },
      staff: [],
      lineData: {},
      fetchingStaff: false,
    };
  },
  watch: {},

  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await liff
        .init({
          liffId: this.liffId,
        })
        .then(() => {
          if (!liff.isLoggedIn()) {
            liff.login({
              redirectUri: window.location.href,
            });
          }
        })
        .catch(() => {
          alert("初始化頁面失敗，請確認此網址是否正確");
        });
      const checker = new LiffChecker(this.$route.params.orgCode);
      this.lineData = await checker.getLineData();
      this.fetchStaff();
      this.fetching = false;
    },
    async fetchStaff() {
      this.fetching = true;
      await staffApi.getStaff(this.staffId).then((res) => {
        this.staff = res;
        this.selectedStaff = this.staff
        this.fetching = false;
      });
    },
    async handleSubmit(callback) {
      try {
        this.fetching = true;
        await this.doSubmit();
        callback(null);
      } catch (error) {
        this.fetching = false;
        callback(error);
      }
    },
    doSubmit() {
      return new Promise((resolve, reject) => {
        staffApi
          .customerBindStaff({
            line_id: this.lineData.profile.userId,
            line_access_token: this.lineData.accessToken,
            sid: this.selectedStaff.id,
            tags: this.tags,
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    displayAvatar(staff) {
      return (
        staff.avatar_url ??
        this.getModuleConfig(
          "staff_specific_page.staffs_list.default_avatar_url"
        )
      );
    },
    displayField(staff, field) {
      if (field.type == "date") {
        return this.$moment(staff[field.matched_key]).format("YYYY-MM-DD");
      }
      return staff[field.matched_key] ?? "";
    },
    selectStaff(staff) {
      this.selectedStaff = staff;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
.sales {
  margin: 5px -16px;
  padding: 16px 0;

  &__wrapper {
    //min-height: 460px;
  }

  &__button {
    opacity: 0.5;
    &__active {
      opacity: 1;
    }
  }

  &__item {
    width: 220px;
    height: revert;

    .card {
      border-radius: 0.5rem;
      height: 100%;

      .card-img-top {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
    }
  }

  ::v-deep.swiper-wrapper {
    align-items: stretch;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: gray;
    background: white;
    border-radius: 100%;
    aspect-ratio: 1;
    width: auto;
    &::after {
      font-size: 1.5rem;
    }
  }
}

.page {
  padding: 5px 5px;
}

::v-deep .spinner-border {
  border-width: 0.2em !important;
}
</style>
